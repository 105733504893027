<template>
    <div class="infos fadein animation-duration-1000">
      <div class="limit">
        <router-link to="/account/login">
          <img src="../../../../assets/images/ommed_logo.svg" class="logo" />
        </router-link>
        <div class="container-form">
          <Form />
        </div>
      </div>
    </div>
  </template>
    
  <script>
  import { ref, computed, onMounted } from "vue";
  import { useRouter } from "vue-router";
  import Form from "./Form"
//   import { PhoneConfirmation } from "./actions"
  
  export default {
    components: {
      Form
    },
    setup() {
      const router = useRouter();
  
  
      onMounted(() => {
        // if(localStorage.getItem("phone")){
        //   const phone = localStorage.getItem("phone").split(" ").join("");
        //   if(phone){
        //     PhoneConfirmation(phone);
        //   }
        // }else{
        //   router.push("/account/infos");
        // }
      })
  
      return {
  
      };
    },
  };
  </script>
    
  <style lang="scss" scoped>
  .infos {
    display: flex;
    flex-direction: column;
    background: #F2F2F2;
    max-width: 100%;
    min-height: 100vh;
  
    .limit {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: max-content;
      max-width: 100%;
      min-height: 100vh;
      padding: 38px;
      a{
        width: max-content;
        padding: 0;
      }
      .logo{
        padding: 0;
      }
    }
  }
  
  
  @media (max-width: 1399px) {
    .register {
      .limit {
        width: 100%;
      }
    }
  }
  
  @media (min-width: 1420px) {
    .register {
      .limit {
        width: 1400px;
      }
    }
  }
  </style>
    