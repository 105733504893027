<template>
    <div class="rg-form">
        <InputText
            :value="modelValue"
            :class="conditionError.class"
            placeholder="RG"
            :maxlength="20"
            @input="updateModel"
        />

        <div class="errormessages" v-if="conditionError.status">
            <p class="p-invalid">{{ msgErros.identityNumber[0] }}</p>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";

export default {
    props: ["modelValue", "msgErros"],
    emits: ["update:modelValue", "callSaveInfo"],
    setup(props, { emit }) {
        const conditionError = computed(() => {
            if(props.msgErros.identityNumber.length && !props.modelValue){
                return {status: true, class: 'p-invalid'};
            }
            return { status: false, class: ''}
        })

        const updateModel = (e) => {
            const value = e.target.value;
            emit("update:modelValue", value);
        }

        return{
            conditionError,
            updateModel,
        }
    },
};
</script>


<style lang="scss" scoped>
.rg-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "Roboto", sans-serif;
    
    .messages{
        display: flex;
        flex-direction: column;
        margin: 4px 0;
        color: #2D313D;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: .4px;
        margin-bottom: 28px;
        margin-top: 8px;
    }
    .errormessages{
        font-size: 12px;
        margin: 4px 0 !important;
    }
}


::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: 1.5px !important;
    text-align: left !important;
    &::placeholder{
        color: #BDBDBD;
    }
}
::v-deep(.p-invalid) {
    border-color: #f44336 !important;
}
::v-deep(.p-input-icon-right) {
    width: 100%;
    .pi{
        margin-right: 10px;
    }
}
</style>